import Cookies from 'js-cookie';
import "./ahoy"

try{
  console.log( window.navigator.userAgent);
  var rules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
    // old chrome android webview agent
    'Linux; U; Android'
  ]
  var webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig')

  window.isWebview = function(ua) {
    return !!ua.match(webviewRegExp)
  }

  if (isWebview(window.navigator.userAgent)){
    if (!Cookies.get('user_has_been_issued_webview_warning')){
      alert("Performance notice: You appear to be viewing this website through an in-app browser. This does not lead to good performance outcomes - please load the site directly in a web browser rather than by clicking a link in the Facebook App, for example. If this is not the case, please let us know and we'll do our best to update this message so it doesn't bother you again. This message will be shown again tomorrow.");
    }
    Cookies.set('user_has_been_issued_webview_warning', true, 18*3600); // set cookie to not show this message for 18 hours - i.e. a day in streaming terms
    console.log(window.jQuery("html").addClass("webview-browser"));
    ahoy.track('WebView Notification', ({ua: window.navigator.userAgent}));
  } else {
    // carry on
  }

  
}catch(e){
    
  // functionToHandleError(e);
  ahoy.track('WebView Test error', ({error: e}));
}

//
//
// // Webview detection function
// function isWebView() {
//     // Check for common webview indicators
//     const userAgent = navigator.userAgent.toLowerCase();
//
//     // iOS webview detection
//     const isIosWebview = /(iphone|ipod|ipad).*applewebkit(?!.*safari)/i.test(userAgent);
//
//     // Android webview detection
//     const isAndroidWebview = /wv/.test(userAgent) ||
//                             /Version\/\d+\.\d+/.test(userAgent) && /Chrome\/\d+\.\d+/.test(userAgent);
//
//     return isIosWebview || isAndroidWebview;
// }
//
// // Function to get the appropriate app store URL
// function getStoreUrl() {
//     const userAgent = navigator.userAgent.toLowerCase();
//     const isIOS = /iphone|ipad|ipod/.test(userAgent);
//
//     if (isIOS) {
//         // iOS Safari
//         return 'https://apps.apple.com/app/safari/id1146562112';
//     } else {
//         // Android Chrome
//         return 'https://play.google.com/store/apps/details?id=com.android.chrome';
//     }
// }
//
// // Function to get the appropriate browser launch URL
// function getBrowserUrl() {
//     const currentUrl = encodeURIComponent(window.location.href);
//     const userAgent = navigator.userAgent.toLowerCase();
//     const isIOS = /iphone|ipad|ipod/.test(userAgent);
//
//     if (isIOS) {
//         // iOS Safari URL scheme
//         return `x-web-search://${currentUrl}`;
//     } else {
//         // Android Chrome URL scheme
//         return `googlechrome://${currentUrl}`;
//     }
// }
//
// // Main redirect function
// function redirectToBrowser() {
//     if (isWebView()) {
//         const browserUrl = getBrowserUrl();
//         const storeUrl = getStoreUrl();
//
//         // Try to open in browser first
//         window.location.href = browserUrl;
//
//         // If browser isn't installed, redirect to store after a delay
//         setTimeout(() => {
//             window.location.href = storeUrl;
//         }, 2500);
//     }
// }
//
// // Example usage
// document.addEventListener('DOMContentLoaded', () => {
//     // Option 1: Automatic redirect
//     redirectToBrowser();
//
//     // Option 2: Show a banner instead
//     if (isWebView()) {
//         const banner = document.createElement('div');
//         banner.style.cssText = `
//             position: fixed;
//             bottom: 0;
//             left: 0;
//             right: 0;
//             background: #333;
//             color: white;
//             padding: 15px;
//             text-align: center;
//             z-index: 9999;
//         `;
//         banner.innerHTML = `
//             <p>For the best experience, please open this page in your browser</p>
//             <button onclick="redirectToBrowser()" style="
//                 background: white;
//                 color: black;
//                 border: none;
//                 padding: 8px 16px;
//                 margin-top: 10px;
//                 border-radius: 4px;
//             ">Open in Browser</button>
//         `;
//         document.body.appendChild(banner);
//     }
// });